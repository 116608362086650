const products = () => {
  const viewport = $(window).width();
  if (viewport < 991) {
    $('#sticky-wp').removeClass('show');
  } else {
    $('#sticky-wp').addClass('show');
  }
};

const navigationMobile = () => {
  $('#button--mobile').on('click', () => {
    $('body').removeClass('search-open');
    $('body').toggleClass('menu-open');
  });
};

const navigationSize = () => {
  $('.search-button').on('click', () => {
    $('body').addClass('search-open');
  });

  $('.search-form .close').on('click', (e) => {
    e.preventDefault();
    $('body').removeClass('search-open');
  });

  const isInnerPage = !!$('#header-page')?.length;
  const position = $(window).scrollTop();
  // const firstLimit = isInnerPage ? 400 : $(window).height() * 0.8;
  const firstLimit = 200;
  const secondLimit = isInnerPage ? 250 : $(window).height() * 0.8;

  if (position > firstLimit) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
    $('.header-bottom').removeClass('animate');
  }

  if (position > secondLimit) {
    $('body').addClass('visible');
    $('.header-bottom').addClass('animate');
  } else {
    $('body').removeClass('visible');
  }

  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > firstLimit) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
      $('.header-bottom').removeClass('animate');
    }

    if ($(e.currentTarget).scrollTop() > secondLimit) {
      $('body').addClass('visible');
      $('.header-bottom').addClass('animate');
    } else {
      $('body').removeClass('visible');
    }
  });
};

const navigationDropdown = () => {
  const viewport = $(window).width();
  if (viewport < 1130) {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      // $('#navigation-main .navigation-sub').hide();
      $('#navigation-main .navigation-sub');
      const isOpen = $(e.currentTarget).next().hasClass('open');
      if (!isOpen) {
        $('#navigation-main .navigation-sub.open').removeClass('open');
        $(e.currentTarget).next().addClass('open');
      } else {
        $(e.currentTarget).next().removeClass('open');
      }
      return false;
    });
  } else {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      const navSub = $(e.currentTarget).parent();
      const isFocus = navSub.hasClass('focus');
      $('#navigation-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      e.stopPropagation();
      return false;
    });
    $('html').on('click', () => {
      $('#navigation-main li[data-navsub]').removeClass('focus');
    });
  }
};

const scrollTop = () => {
  const domElm = $('#button--scroll-top');
  domElm.fadeOut(0);
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 220) {
      domElm.fadeIn(300);
    } else {
      domElm.fadeOut(300);
    }
  });
  domElm.on('click', () => {
    $('html, body').animate({
      scrollTop: 0,
    }, '500');
    return false;
  });
};

navigationMobile();
navigationDropdown();
navigationSize();
scrollTop();
products();
