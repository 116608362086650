import {loadModal} from "../components/bootstrap-modal";


function submitOnChange() {
    $('.submitOnChange').on('change', function(){
        $(this).parent().parent().find('form').first().submit();
    });
}
function submitOnCheck() {
    $('.submitOnCheck').on('change', function(){
        $(this).parent().parent().parent().parent().parent().find('form').first().submit();
    });
}
submitOnChange();
submitOnCheck();

if ($("#produit-detail").length) {
    $("#produit-detail select").change(function() {
        var attributs = [];
        $('select.attributs').each(function( index ) {
            attributs.push($(this).val());
        });
        $.ajax({
            method: "PUT",
            url: $("#produit-detail").data('url'),
            data: {
                'attributs': attributs,
                'quantite': $('#quantite').val(),
                '_token': $("#produit-detail").data('csrftoken')
            },
            success: function(data) {
                $('#add-panier').html(data.view_add_panier);
                $('#price').html(data.prix);
            }
        });
    });
    $("#produit-detail select").first().change();
}

$('.form-modal').on('submit', function() {
    var href  = $(this).attr('action');
    var size = $(this).data('size');
    var align = $(this).data('align');
    $('#modal .modal-dialog').addClass(size).addClass(align);
    console.log($(this).serializeArray())
    loadModal(href, $(this).serializeArray());
    return false;
});
