import 'sticky-kit/dist/sticky-kit';

const stickykitInit = () => {
  const viewport = $(window).width();
  if (viewport > 991) {
    $('#sticky-wp').stick_in_parent({
      offset_top: 100,
    });
  } else {
    $('#sticky-wp').trigger('sticky_kit:detach');
  }
};

setTimeout(() => {
  stickykitInit();
}, 100);

$(window).on('resize', () => {
  stickykitInit();
});
