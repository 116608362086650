import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$('#slick-header').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  fade: true,
  arrows: false,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  prevArrow: '<button class="arrow-prev"><span class="sprite carousel-prev"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite carousel-next"></span></button>',
  responsive: [{
    breakpoint: 1200,
    settings: {},
  },
  {
    breakpoint: 991,
    settings: {},
  },
  {
    breakpoint: 576,
    settings: {},
  },
  ],
});

$('#slick-product').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  infinite: false,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
    breakpoint: 1200,
    settings: {},
  },
  {
    breakpoint: 1000,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 576,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  ],
});

$('#slick-home-universe').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  appendArrows: '#slick-home-universe-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-left-black"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-right-black"></span></button>',
  responsive: [{
    breakpoint: 1200,
    settings: {},
  },
  {
    breakpoint: 991,
    settings: {},
  },
  {
    breakpoint: 576,
    settings: {},
  },
  ],
});

$('.home-universe-navigation button').on('click', (e) => {
  const { currentTarget } = e;
  const slickIndex = +currentTarget.getAttribute('data-slick');
  $('#slick-home-universe').slick('slickGoTo', slickIndex);
});

$('#slick-home-universe').on('afterChange', (_, __, currentSlide) => {
  $('.home-universe-navigation button').removeClass('active');
  $(`.home-universe-navigation button[data-slick="${currentSlide}"]`).addClass('active');
});

const slickHomeHeight = () => {
  function action() {
    const viewport = $(window).height();
    $('#slick-header-wp.home').height(viewport - 40);
    $('#slick-header-wp.home .item').height(viewport - 40);
  }
  action();
  $(window).on('resize', () => {
    action();
  });
};

slickHomeHeight();
