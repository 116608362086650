import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-fr';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';

const lang = $('body').attr('data-lang');

$('#datepicker').datepicker({
  regional: lang,
  showOn: 'both',
  format: 'yyyy-mm-dd',
  buttonText: '',
  buttonImage: 'theme/styles/assets/icons/calendar-today.png',
  buttonImageOnly: false,
  defaultDate: '+1w',
  numberOfMonths: 1,
  minDate: 2,
  maxDate: '1Y',
  beforeShowDay(date){
      if(!$(this).data('weekend')){
          return $.datepicker.noWeekends(date)
      }else{
          return [true, ''];
      }
  },
  beforeShow() {
    $('.datepicker-wp').append($('#ui-datepicker-div'));
  },
    onSelect(dateText) {
        const $dateField = $('#datepicker');
        $dateField.removeClass('parsley-error');
        $('.datepicker-error').html('');
    },

});
