$('.filter-btn').on('click', function () {
    f_filter();
});

$('#sort_btn').on('change', function () {
    f_filter();
});

export function f_filter(link) {
    if(link == undefined){
        link = $("#filtre").data('ajax-url') ;
    }
    console.log($("#filtre").serialize())
    $.ajax({
        method: "GET",
        url: link,
        data: $("#filtre").serialize(),
        success: function(data) {
            $("#products-list").html(data.liste);
            event_pagniate();
        },
        error: function (request) {
            if(request.responseJSON.message) {
                const html = '<div class="alert alert-danger" role="alert">' + request.responseJSON.message + '</div>';
                $('#error-message').html(html);
            }
        }
    });
}

function event_pagniate() {
    $('#products-list .page-link').on('click', function (event) {
        var link = $(this).attr('href');
        event.preventDefault();
        f_filter(link);
    });
}
event_pagniate();
